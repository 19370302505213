
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,600&display=swap');

body{
  background-color: #2195F2;
  padding: 30px 87px;
  font-family: 'Poppins', sans-serif;
  max-width: 1600px;
  margin: auto;
}
#root{
  background: #fff;
}
.profile-page nav{
  padding: 15px 87px;
  border-bottom: 1px solid #eee;
}
.navbar-brand{
  font-family: monospace;
  font-size: 38px;
}
.nav-item{
  padding: 0px 15px;
}
.nav-item a{
  font-size: 16px;
  color: #000;
}
.profile-pic img{
  border-radius: 50%;
  margin-left: 10px;
  width: 35px;
  height: 35px;
  object-fit: cover;
}
.flex
{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.sidebar{
  width: 35%;
  padding: 40px 87px;
}
.sidebar img{
  border-radius: 10px;
  height: 300px;
  object-fit: cover;
  width: 100%;
}
.sidebar-work span{
  color: #787878ee;
    padding-top: 20px;
    display: inline-block;
}
.sidebar-work{
  display: flex;
} 
.sidebar-box hr{
  margin-top: 38px;
  margin-left: 5px;
  display: inline-block;
  width: 100%;
}
.mywork-company{
  margin-top: 10px;
  margin-bottom: 25px;
}
.mywork-company-inner
{
  display: flex;
  justify-content: space-between;
}
.mywork-company-inner h2{
font-size: 22px;
opacity: .8;
}
.mywork-company-inner span{
  background-color: #DAE9F3;
  padding: 10px;
  display: inline-block;
  color: #2195F2;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
}
.mywork-company p{
  opacity: .9;
  font-size: 14px;
}
.sidebar-box ul{
  list-style: none;
  padding-left: 0;
  font-weight: 500;
}
.main
{
  width: 65%;
  padding: 20px;
  padding-right: 100px;
}
.main .flex{
  justify-content: flex-start;
}
.main .flex h2{
  width: 100%;
  border-bottom: 1px solid rgb(201, 201, 201);
  margin-top: 20px;
  font-size: 20px;
  padding-bottom: 5px;
}
.main .flex h5{
text-transform: uppercase;
font-size: 14px;
opacity: .8;  
margin-top: 30px;
}
.main .flex .title{
  font-weight: 500;
}
.main .flex .title, .main .flex .content{
padding:20px 10px 20px 0px;
width: 25%;
}
.main .myname h1{
  opacity: .8;
  font-size: 32px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.main .myname p{
  color: #2195F2;
  font-size: 16px;
  font-weight: 500;
}
.main .location{
  margin-top: 25px;
  font-size: 12px;
  margin-left: 30px;
}
.main .rankings{
  margin-top: 10px;
  margin-bottom: 20px;
}
.main .rankings h4{
  font-size: 16px;
  opacity: .7;
  width: 100%;
  text-transform: uppercase;
}
.main .rankings span{
  font-size: 22px;
  font-weight: 500;
}
@media screen and (max-width:991px) {
  body, .sidebar, .profile-page nav{
    padding-left: 15px;
    padding-right: 15px;
  }
  .main .flex .title, .main .flex .content{
    width: 40%;
  }
}
@media screen and (max-width:767px) {
.sidebar{
  width: 100%;
}
.sidebar img{
  object-position: top;
}
.main{
  width: 100%;
  padding-right: 20px;
}
/* .main .location{
  margin-top: 0;
  margin-left: 0;
} */
.main .flex .title{
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0px;
}
.main .flex .content{
  width: 100%;
  padding-top: 0;
}
}